<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      // requestType: 'GET',
    };
  },
  mounted() {
    this.getConfigList('rewards_for_scanning_QR_codes_for_group_buyers_report_list');
  },
  methods: {
  },
};
</script>
